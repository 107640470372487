import dayjs from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import 'antd/lib/date-picker/style/index';

import { Tooltip } from 'components/common';

const AntDatePicker = generatePicker(dayjsGenerateConfig);

const { RangePicker } = AntDatePicker;

const DatePicker = ({
  type,
  helpText,
  value,
  onChange,
  containerStyle,
  ...rest
}) => {
  let _value = undefined;

  if (value) {
    _value = Array.isArray(value) ? value.map(dayjs) : dayjs(value);
  }

  const handleChange = value => {
    if (value) {
      return Array.isArray(value)
        ? onChange(value.map(v => v.toJSON()))
        : onChange(value.toJSON());
    }
    onChange(value);
  };

  let Component = AntDatePicker;

  if (type === 'range') {
    Component = RangePicker;
  }

  return (
    <Tooltip title={helpText} placement='right'>
      <div style={containerStyle}>
        <Component
          {...rest}
          value={_value}
          onChange={handleChange}
          getPopupContainer={triggerNode => triggerNode.parentElement}
          style={{ width: '100%' }}
        />
      </div>
    </Tooltip>
  );
};

export default DatePicker;
