import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v7 as uuidv7 } from 'uuid';
import { usePost } from 'hooks';
import { cancelSearch } from 'services/api';
import Button from '../Button';

export const id = uuidv7();

function SearchButton({
  loading,
  disabled,
  onSearch,
  onCancel,
  location,
  cancelTimeout = 1000,
  searchText = 'Search',
  ...props
}) {
  const [, postCancel] = usePost({ url: cancelSearch });
  const [showCancel, setShowCancel] = useState(false);
  useEffect(() => {
    let timeoutId;
    if (loading) {
      timeoutId = setTimeout(() => {
        setShowCancel(true);
      }, cancelTimeout);
    } else {
      setShowCancel(false);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [loading, cancelTimeout]);

  const handleCancel = useCallback(() => {
    onCancel();
    postCancel({
      id,
    });
  }, [onCancel, postCancel]);
  return (
    <Button
      disabled={!showCancel && disabled}
      onClick={loading && showCancel ? handleCancel : onSearch}
      {...props}
    >
      {loading && showCancel ? 'Cancel' : searchText}
    </Button>
  );
}

SearchButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onSearch: PropTypes.func,
  onCancel: PropTypes.func,
  location: PropTypes.string,
  cancelTimeout: PropTypes.number,
  searchText: PropTypes.string,
};

export default SearchButton;
