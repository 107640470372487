import { Button, Divider, Select as AntSelect, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { colours } from 'utils/theme';
import styled from 'styled-components';
import { useGet } from 'hooks';
import Loader from './Loader';
import debounce from 'lodash.debounce';

import { Tooltip } from 'components/common';
import { Icon } from 'components/common';

const { Option } = AntSelect;

const Container = styled.div`
  .button-add-to-new-list {
    color: ${colours.linkBlue};
    font-weight: bold;
    background: transparent;
  }
  .button-add-to-new-list:hover {
    background: ${colours.linkBlue};
    border-color: ${colours.linkBlue};
    color: white;
  }
  .button-add-to-new-list:focus {
    background: transparent;
    color: ${colours.linkBlue};
  }
  .button-add-to-new-list:active {
    background: transparent;
    color: ${colours.linkBlue};
  }
`;

const renderOptions = (
  dataTestId,
  renderOption,
  options,
  disabledTooltipText
) => {
  return options?.map(
    renderOption
      ? renderOption
      : (o, i) => {
          return (
            <Option
              title={o.label}
              key={`${o.value}-${i}`}
              value={o.value}
              label={o.label}
              data-testid={`${dataTestId}-option-${i}`}
              details={o}
              disabled={o.isDisabled}
            >
              <Tooltip
                title={o.isDisabled ? disabledTooltipText : ''}
                placement='top'
              >
                <div style={{ whiteSpace: 'wrap' }}>
                  {o.isDisabled && (
                    <span style={{ marginRight: '8px' }}>
                      <Icon size='1x' icon='error' color={'#d9d9d9'} />
                    </span>
                  )}
                  {o.label}
                </div>
              </Tooltip>
            </Option>
          );
        }
  );
};

const Select = React.forwardRef(
  (
    {
      options = [],
      renderOption,
      'data-testid': dataTestId,
      onCreateNewItem,
      buttonText,
      helpText,
      placeholder,
      value = [],
      remoteSearchOnClick,
      disableTooltip,
      otherRemoteParams,
      onChange,
      emptyOption,
      minInputSize = 0,
      optionProp,
      disabledKey,
      onBlurChange,
      disabledTooltipText,
      ...rest
    },
    _
  ) => {
    const refButton = useRef();
    const refSelect = useRef();
    const refContainer = useRef();
    const refSpace = useRef();

    const [{ res, loading }, getOptions] = useGet({
      url: options,
    });

    const debounced = debounce(
      value => getOptions({ query: { name: value, ...otherRemoteParams } }),
      300
    );
    const [localOptions, setLocalOptions] = useState(
      typeof options !== 'string' ? options : []
    );

    const [optionDictionary, setOptionDictionary] = useState({});
    const [inputValue, setInputValue] = useState(undefined);

    useEffect(() => {
      if (res && res !== localOptions) {
        if (optionProp) {
          const { optionLabel, optionValue } = optionProp;
          setLocalOptions(
            res.map(r => ({
              label: r[optionLabel],
              value: r[optionValue],
              isDisabled: disabledKey && r[disabledKey],
            }))
          );

          setOptionDictionary(prevState => ({
            ...prevState,
            ...Object.fromEntries(res.map(s => [s[optionValue], s])),
          }));
        } else {
          setLocalOptions(res);
        }
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [res, optionProp, disabledKey]);

    useEffect(() => {
      if (optionProp) {
        const { optionValue } = optionProp;

        const newOptions = value.filter(r => !optionDictionary[r[optionValue]]);

        if (newOptions.length > 0) {
          const updatedDictionary = {
            ...optionDictionary,
            ...Object.fromEntries(newOptions.map(s => [s[optionValue], s])),
          };
          setOptionDictionary(updatedDictionary);
        }
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionProp, value]);

    useEffect(() => {
      if (options && typeof options !== 'string') {
        setLocalOptions(options);
      }
    }, [options]);

    const onInputChange = value => {
      if (value !== inputValue) {
        setInputValue(value);
        if (typeof options === 'string') {
          debounced(value);
        }
      }
    };

    const handleChange = (value, option) => {
      onChange(
        optionProp ? value.map(v => optionDictionary[v]) : value,
        option
      );
    };

    const handleBlur = () => {
      onInputChange('');
      if (onBlurChange) onBlurChange();
    };

    const handleClick = () => {
      onInputChange('');
    };

    return (
      <Tooltip
        title={disableTooltip ? '' : value?.length ? placeholder : helpText}
        placement='right'
      >
        <AntSelect
          {...rest}
          showArrow
          value={
            optionProp
              ? value?.map(v => ({
                  value: v[optionProp.optionValue],
                  label: v[optionProp.optionLabel],
                  isDisabled: disabledKey && v[disabledKey],
                  isTest: true,
                }))
              : value
          }
          placeholder={placeholder}
          filterOption={typeof options !== 'string'}
          ref={refSelect}
          onSearch={onInputChange}
          showSearch={true}
          optionFilterProp='label'
          onClick={_ =>
            remoteSearchOnClick &&
            (inputValue === undefined
              ? handleClick()
              : onInputChange(inputValue))
          }
          onBlur={handleBlur}
          onChange={handleChange}
          data-testid={dataTestId}
          optionLabelProp={optionProp?.optionLabel || undefined}
          notFoundContent={
            loading ? (
              <Loader />
            ) : emptyOption && inputValue?.length >= minInputSize ? (
              emptyOption(inputValue)
            ) : null
          }
          dropdownRender={menu => (
            <>
              {menu}
              {onCreateNewItem != null && (
                <>
                  <Divider style={{ margin: '2px 0' }}></Divider>
                  <Space
                    direction='horizontal'
                    style={{ width: '100%', justifyContent: 'center' }} // background: '#f7f3ed'
                  >
                    <Container ref={refContainer}>
                      <Button
                        ref={refButton}
                        className='button-add-to-new-list'
                        onClick={() => {
                          refButton.current && refButton.current.blur();
                          refContainer.current && refContainer.current.blur();
                          refSpace.current && refSpace.current.blur();
                          refSelect.current && refSelect.current.blur();
                          onCreateNewItem();
                        }}
                        type='text'
                        size='small'
                        style={{ margin: '0 0px 1px 0' }}
                      >
                        {buttonText}
                      </Button>
                    </Container>
                  </Space>
                </>
              )}
            </>
          )}
          getPopupContainer={triggerNode => triggerNode.parentElement}
        >
          {renderOptions(
            dataTestId,
            renderOption,
            localOptions,
            disabledTooltipText
          )}
        </AntSelect>
      </Tooltip>
    );
  }
);

export default Select;
