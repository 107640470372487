import { views } from 'components/Lists/constants';

// Auth
export const currentUser = '/api/user/current';
export const logInUser = '/api/auth/login';
export const logInExternalUser = '/api/auth/external-login';
export const logOutUser = '/api/auth/logout';
export const verifyUserCode = '/api/auth/verify-code';
export const resetUserPassword = '/api/user/resetpassword';
export const completeReset = '/api/user/completereset';
export const completeSetPassword = '/api/user/complete-set-password';
export const failedLogin = '/api/user/failedlogin';
export const verifySetPasswordToken = token =>
  `/api/user/verify-set-password-token/${token}`;
export const passwordCheck = `/api/user/password-check`;
export const verifyResetPasswordToken = token =>
  `/api/user/verify-reset-password-token/${token}`;
export const refreshToken = '/api/auth/refresh-token';

// Rate limits
export const resetRateLimits = '/api/rate-limits/reset';
// Search
export const organisationSearch = '/api/search/organisation';
export const organisationInsightsSearch = '/api/search/organisation/insights';
export const researcherSearch = '/api/search/researcher';
export const researcherHighlights = '/api/search/activity/highlights';
export const activitySearch = '/api/search/activity';
// export const researcherSearch = '/api/universal/search';
export const researcherGeoSearch = '/api/universal/geo-search';
export const organisationGeoSearch = '/api/search/organisation/geo';
export const addSearchToList = id =>
  `/api/researcherlist/${id}/members/fromsearch`;
export const addSearchToOrgList = id =>
  `/api/organisationlists/${id}/members/fromsearch`;
export const cancelSearch = '/api/search/cancel';

export const researchersLists = `/api/researcherlists/forresearchers`;
export const organisationsLists = `/api/organisationlists/formembers`;
export const exportSearch = '/api/universal/download';
export const checkExportSearch = '/api/universal/download/check';

export const largeWarning = downloadType =>
  `/api/user/largewarning?downloadType=${downloadType}`;

// Purchasing Search
export const purchasingSearch = '/api/purchasing-search';
export const exportPurchasing = '/api/purchasing-search/download';

// Lists
export const getListsForUser = '/api/researcherlists/';
export const getCRMLists = '/api/researcherlists/crm';
export const getMembersForList = (id, view = views.researchers) =>
  view === views.researchers
    ? `/api/researcherlist/${id}/members`
    : `/api/organisationlists/${id}/members`;
export const researcherListPermissions = '/api/researcherlists/permissions';
export const organisationListPermissions = '/api/organisationlists/permissions';
export const disableList = (id, view = views.researchers) =>
  view === views.researchers
    ? `/api/researcherlist/${id}/disable`
    : `/api/organisationlists/${id}/disable`;
export const enableList = (id, view = views.researchers) =>
  view === views.researchers
    ? `/api/researcherlist/${id}/enable`
    : `/api/organisationlists/${id}/enable`;
export const deleteList = (id, view = views.researchers) =>
  view === views.researchers
    ? `/api/researcherlist/${id}`
    : `/api/organisationlists/${id}`;
export const updateAccessLevels = id => `/api/researcherlist/${id}/settings`;
export const createList = (view = views.researchers) =>
  view === views.researchers ? '/api/researcherlist' : '/api/organisationlists';
export const updateList = (id, view = views.researchers) =>
  view === views.researchers
    ? '/api/researcherlist'
    : `/api/organisationlists/${id}`;
export const exportMembers = (id, view = views.researchers) =>
  view === views.researchers
    ? `/api/researcherlist/${id}/export`
    : `/api/organisationlists/${id}/export`;
export const manageMembers = (id, view = views.researchers) =>
  view === views.researchers
    ? `/api/researcherlist/${id}/members`
    : `/api/organisationlists/${id}/members`;
export const pushMembers = id => `/api/researcherlist/${id}/pushtocrm`;

export const getOrgListsForUser = '/api/organisationlists';

// Researcher
export const getResearcher = '/api/researcher/profile';
export const unfollowResearcher = '/api/alert/unfollow';
export const bulkUnfollowResearcher = '/api/alert/bulk-unfollow';
export const reportResearcher = '/api/researcher/report';
export const exportNetwork = '/api/researcher/network';
export const searchByFullName = '/api/universal/filter/searchbar';

export const allExportHistory = '/api/exportHistory/all';
export const refreshExportHistory = id => `/api/exportHistory/${id}`;
export const getUserCredits = '/api/user/getCredits';

//Organisation
export const getSuperOrganisation = id => `/api/superorganisation/${id}`;
export const searchSuperOrganisationResearchers = id =>
  `/api/search/organisation/${id}/researcher`;
export const unfollowOrganisation = '/api/organisation_alert/unfollow';
export const bulkUnfollowOrganisation = '/api/organisation_alert/bulk-unfollow';
export const searchSuperOrganisationWorkItems = id =>
  `/api/search/organisation/${id}/workitems`;
export const searchSuperOrganisationClinicalTrials = id =>
  `/api/search/organisation/${id}/clinicaltrials`;
export const reportOrganisation = '/api/superorganisation/report';

// Alerts
export const userAlerts = '/api/alert';
export const sanityCheckAlert = '/api/alert/sanityCheck';

export const userOrganisationAlerts = '/api/organisation_alert';
export const userFollowedResearchers = '/api/alert/followedResearchers';
export const userFollowedOrganisations =
  '/api/organisation_alert/followedOrganisations';

// Calendar
export const tradeshowCalendar = '/api/trade-shows/calendar';
export const tradeshowCalendarYears = '/api/trade-shows/years';
export const requestTradeshow = '/api/trade-shows/request';
export const tradeshowNames = '/api/universal/filter/tradeshow-names/';
export const tradeshowDownload = '/api/tradeshow/download';
export const researcherTradeshows = researcherId =>
  `/api/trade-shows/by-researcher/${researcherId}`;

// AI
export const aiMessage = '/api/ai/message';

// Project
export const getProject = id => `/api/project/${id}`;
export const findEmail = '/api/finder';

// Poster
export const getPoster = '/api/trade-shows/poster';

// Publication
export const getPublication = '/api/pubmed/item';
export const getKeywords = '/api/pubmed/item/keywords';

// Trial
export const getTrial = '/api/clinicaltrials/trial';

// Item
export const getItem = id => `/api/purchasing/item/${id}`;

// Settings
// // My Account
export const autoRenew = '/api/settings/autorenew';
export const toggleAutoRenew = '/api/account/license/autorenew';
export const updateUser = '/api/user/update';
export const changePassword = '/api/user/changepassword';
// // CRM Integrations
export const getSalesforceData = '/api/crm/settings/Salesforce';
export const saveSalesforceData = '/api/crm/settings/Salesforce';
export const disconnectSalesforce = '/api/settings/crmlogout/Salesforce';
export const crmSettings = '/api/crm/settings';
export const getHubSpotData = '/api/crm/settings/Hubspot';
export const saveHubSpotData = '/api/crm/settings/Hubspot';
export const disconnectHubSpot = '/api/settings/crmlogout/Hubspot';
// // User Management
export const allUsers = '/api/account/users/all';
export const allUserAccounts = '/api/account/users/allUserAccounts';

export const allUsersIncludeInactive =
  '/api/account/users/all?includeInactive=true';
export const editUser = '/api/account/users/update';
export const createUser = '/api/account/users/create';
export const toggleUser = '/api/account/users/changestatus';
export const resetPassword = '/api/account/users/passwordreset';
export const toggleTradeshowRequester =
  '/api/account/users/toggletradeshowrequester';
export const toggleCanPushToSalesforce =
  '/api/account/users/togglecanpushtosalesforce';
export const toggleCanPushToHubspot =
  '/api/account/users/togglecanpushtohubspot';
export const toggleUserManager = '/api/account/users/toggleusermanager';

// User Settings
export const userSettings = '/api/userSettings';

// // User Report
export const userReport = '/api/account/users/report/';
export const userSearchHistoryReport = '/api/account/users/historyReport/';
export const userSearchHistoryReportExport =
  '/api/account/users/historyReportExport';

// // Keyword Management
export const userKeywords = '/api/settings/keywords';
export const userHasKeywords = '/api/settings/keywords/exist';

// // Email Request
export const emailRequests = '/api/email-requests';
// Live Chat
export const chatOpen = '/api/user/livehelp/open';
export const chatClose = '/api/user/livehelp/close';

// Logging
export const log = '/api/log/ui';

// SF Enquiry
export const salesEnquiry = '/api/enquiry';

// Downloads
export const downloadExport = id => `/api/downloads/ui/${id}`;

// NPS
export const netPromoterScorePrompt = '/api/nps/net-promotor-score-prompt';
export const netPromoterScore = '/api/nps/net-promoter-score';
